import React from 'react'
import { graphql, Link } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'

const Index = (props) => {
  const { localFile: { url }, alt } = props.data.indexImage.data.image
  return (
    <React.Fragment>
      <SEO />
      <MainWrapper>
        <Link to="/actualites/">
          <img src={url} style={{width: '100%'}} alt={alt} />
        </Link>
      </MainWrapper>
    </React.Fragment>
  )
}

export default Index;
export const pageQuery = graphql`
  query Index {
    indexImage: prismicHomepageimage {
      data {
        image {
          alt
          localFile {
            url
          }
        }
      }
    }
  }
`